import React, { FC } from 'react';
import './ContentContainer.scss';

interface IContentContainer {
  children: any;
  title?: string;
}

const ContentContainer: FC<IContentContainer> = ({
  children,
  title,
}: IContentContainer) => {
  return (
    <div className="contentContainer">
      <div className="contentContainer__inner">
        {title && <h1 className="contentContainer__title">{title}</h1>}
        <div className="contentContainer__body">
          {children}
        </div>
      </div>
    </div>
  );
};

ContentContainer.defaultProps = {
  title: '',
};

export default ContentContainer;
