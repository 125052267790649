import React, { ReactNode } from 'react';
import ContentContainer from '../components/ContentContainer';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { ContactHelper } from '../utils';
import { Layout } from '../components';
import './contact.scss';

export default function ContactPage(): ReactNode {
  const { title: siteTitle, author } = useSiteMetadata();
  const email = ContactHelper.getContactHref('email', author.contacts.email);
  const github = ContactHelper.getContactHref('github', author.contacts.github);
  const stackoverflow = ContactHelper.getContactHref('stackoverflow', author.contacts.stackoverflow);
  const linkedin = ContactHelper.getContactHref('linkedin', author.contacts.linkedin);

  return (
    <Layout title={siteTitle}>
      <ContentContainer>
        <h1 className="contact__title">Contact Me</h1>
        <p>
          Please feel free to contact me via any of the following methods: &nbsp;
          <ul>
            <li><a href={email}>Email</a></li>
            <li>
              <a
                href={github}
                target="_blank"
                rel="noreferrer"
              >
                GitHub
              </a>
            </li>
            <li>
              <a
                href={stackoverflow}
                target="_blank"
                rel="noreferrer"
              >
                StackOverflow
              </a>

            </li>
            <li>
              <a
                href={linkedin}
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>

            </li>
          </ul>
        </p>
      </ContentContainer>
    </Layout>
  );
}
